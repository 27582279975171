var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import Vue from "vue";
import { createMetaDescription } from "../../utils/meta";
export default Vue.extend({
    props: {
        breadcrumbs: {
            type: Array,
            default: undefined,
        },
        title: {
            type: String,
            default: undefined,
        },
        subTitle: {
            type: String,
            default: undefined,
        },
        metaTitle: {
            type: String,
            default: undefined,
        },
        metaDescription: {
            type: String,
            default: undefined,
        },
        sectionClass: {
            type: String,
            default: undefined,
        },
    },
    metaInfo: function () {
        var info = { title: this.metaTitle || this.title };
        if (!this.metaDescription) {
            return info;
        }
        return __assign(__assign({}, info), { meta: [createMetaDescription(this.metaDescription)] });
    },
});
