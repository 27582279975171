import VueRouter from "vue-router";
import partial from "lodash/partial";
import { requireAuth, requireNoAuth } from "./auth";
var nonScrollToTopPrefixes = [
    "/faqs",
    "/health-cover/details",
];
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export function createRouter(_a) {
    var store = _a.store, locale = _a.locale, currency = _a.currency;
    return new VueRouter({
        mode: "history",
        base: "/".concat(locale, "/").concat(currency.toLowerCase(), "/"),
        routes: [
            {
                path: "/sitemap.xml",
                component: function () { return import("../components/public/Sitemap.vue"); },
            },
            {
                path: "/sys/test-exception/",
                component: function () { return import("../components/TestException.vue"); },
            },
            {
                path: "",
                component: function () { return import("../components/GlobalLayout.vue"); },
                children: [
                    {
                        path: "/dashboard",
                        component: function () { return import("../components/dashboard/Layout.vue"); },
                        beforeEnter: partial(requireAuth, "student", store),
                        children: [
                            {
                                path: "",
                                component: function () { return import("../components/dashboard/Landing.vue"); },
                            },
                            {
                                path: "purchases",
                                component: function () {
                                    return import("../components/dashboard/Purchases.vue");
                                },
                            },
                            {
                                path: "saved-properties",
                                component: function () {
                                    return import("../components/dashboard/SavedProperties.vue");
                                },
                            },
                            {
                                path: "account",
                                component: function () { return import("../components/dashboard/Account.vue"); },
                            },
                        ],
                    },
                    {
                        path: "/agent-dashboard",
                        component: function () { return import("../components/agents/Layout.vue"); },
                        beforeEnter: partial(requireAuth, "agent", store),
                        children: [
                            {
                                path: "",
                                component: function () { return import("../components/agents/Landing.vue"); },
                            },
                            {
                                path: "add-students",
                                component: function () { return import("../components/agents/AddStudents.vue"); },
                            },
                            {
                                path: "students",
                                component: function () { return import("../components/agents/Students.vue"); },
                            },
                            {
                                path: "commission",
                                component: function () { return import("../components/agents/Commission.vue"); },
                            },
                            {
                                path: "account",
                                component: function () { return import("../components/agents/Account.vue"); },
                            },
                        ],
                    },
                    {
                        path: "/",
                        component: function () { return import("../components/public/PublicLayout.vue"); },
                        children: [
                            {
                                path: "",
                                component: function () {
                                    return import("../components/public/general/Home.vue");
                                },
                            },
                            {
                                path: "accommodation",
                                component: function () {
                                    return import("../components/public/accommodation/Landing.vue");
                                },
                            },
                            {
                                path: "accommodation/listing/:slug",
                                component: function () {
                                    return import("../components/public/accommodation/Listing.vue");
                                },
                            },
                            {
                                path: "accommodation/listing/:slug/:leaseTermId/enquire-now",
                                component: function () {
                                    return import("../components/public/accommodation/enquiry/EnquireNow.vue");
                                },
                            },
                            {
                                path: "accommodation/listing/:slug/enquiry-submitted",
                                component: function () {
                                    return import("../components/public/accommodation/enquiry/EnquirySubmitted.vue");
                                },
                            },
                            {
                                path: "accommodation/listing/:slug/enquiry-save-for-next/:enquiryId",
                                component: function () {
                                    return import("../components/public/accommodation/enquiry/EnquirySaveForNext.vue");
                                },
                            },
                            {
                                path: "accommodation/payment/:id",
                                component: function () {
                                    return import("../components/public/accommodation/payment/Payment.vue");
                                },
                            },
                            {
                                path: "accommodation/payment/:id/success",
                                component: function () {
                                    return import("../components/public/accommodation/payment/Success.vue");
                                },
                            },
                            {
                                path: "accommodation/:citySlug",
                                component: function () {
                                    return import("../components/public/accommodation/Search.vue");
                                },
                            },
                            {
                                path: "accommodation/:citySlug/:institutionSlug",
                                component: function () {
                                    return import("../components/public/accommodation/Search.vue");
                                },
                            },
                            {
                                path: "health-cover",
                                component: function () {
                                    return import("../components/public/health/Landing.vue");
                                },
                            },
                            {
                                path: "health-cover/details",
                                component: function () {
                                    return import("../components/public/health/Listing.vue");
                                },
                            },
                            {
                                path: "health-cover/details/:coverFor/:courseStart/:courseEnd",
                                component: function () {
                                    return import("../components/public/health/Listing.vue");
                                },
                            },
                            {
                                path: "mobile-and-data",
                                component: function () {
                                    return import("../components/public/mobile/Landing.vue");
                                },
                            },
                            {
                                path: "mobile-and-data/:slug",
                                component: function () {
                                    return import("../components/public/mobile/Listing.vue");
                                },
                            },
                            {
                                path: "banking",
                                component: function () {
                                    return import("../components/public/banking/Landing.vue");
                                },
                            },
                            {
                                path: "about",
                                component: function () {
                                    return import("../components/public/general/About.vue");
                                },
                            },
                            {
                                path: "agents",
                                component: function () {
                                    return import("../components/public/general/Agents.vue");
                                },
                            },
                            {
                                path: "blog",
                                component: function () {
                                    return import("../components/public/general/Blog.vue");
                                },
                            },
                            {
                                path: "blog/:slug",
                                component: function () {
                                    return import("../components/public/general/BlogPost.vue");
                                },
                            },
                            {
                                path: "terms-and-conditions",
                                props: { pageKey: "terms" },
                                component: function () {
                                    return import("../components/public/general/ContentPage.vue");
                                },
                            },
                            {
                                path: "terms-and-conditions/mobile-and-data",
                                props: { pageKey: "terms-mobile" },
                                component: function () {
                                    return import("../components/public/general/ContentPage.vue");
                                },
                            },
                            {
                                path: "terms-and-conditions/health-cover",
                                props: { pageKey: "terms-health" },
                                component: function () {
                                    return import("../components/public/general/ContentPage.vue");
                                },
                            },
                            {
                                path: "terms-and-conditions/accommodation",
                                props: { pageKey: "terms-accommodation" },
                                component: function () {
                                    return import("../components/public/general/ContentPage.vue");
                                },
                            },
                            {
                                path: "terms-and-conditions/agents",
                                props: { pageKey: "terms-agents" },
                                component: function () {
                                    return import("../components/public/general/ContentPage.vue");
                                },
                            },
                            {
                                path: "contact",
                                component: function () {
                                    return import("../components/public/general/Contact.vue");
                                },
                            },
                            {
                                path: "faqs",
                                component: function () {
                                    return import("../components/public/general/Faqs.vue");
                                },
                            },
                            {
                                path: "faqs/:category",
                                component: function () {
                                    return import("../components/public/general/Faqs.vue");
                                },
                            },
                            {
                                path: "cart",
                                component: function () {
                                    return import("../components/public/cart/Landing.vue");
                                },
                            },
                            {
                                path: "cart/checkout",
                                component: function () {
                                    return import("../components/public/cart/Checkout.vue");
                                },
                            },
                            {
                                path: "cart/checkout/bank-transfer/:pendingPurchaseId",
                                component: function () {
                                    return import("../components/public/cart/BankTransferPurchase.vue");
                                },
                            },
                            {
                                path: "purchase/:id/save-for-next",
                                component: function () {
                                    return import("../components/public/cart/SaveForNextTime.vue");
                                },
                            },
                            {
                                path: "purchase/:cartId/success",
                                component: function () {
                                    return import("../components/public/cart/Success.vue");
                                },
                            },
                            {
                                path: "agent-welcome/:id",
                                component: function () { return import("../components/auth/AgentWelcome.vue"); },
                                beforeEnter: partial(requireNoAuth, store),
                            },
                            {
                                path: "student-welcome/:id",
                                component: function () {
                                    return import("../components/auth/StudentWelcome.vue");
                                },
                                beforeEnter: partial(requireNoAuth, store),
                            },
                            {
                                path: "sign-in",
                                component: function () { return import("../components/auth/SignIn.vue"); },
                                beforeEnter: partial(requireNoAuth, store),
                            },
                            {
                                path: "sign-up",
                                component: function () { return import("../components/auth/SignIn.vue"); },
                                beforeEnter: partial(requireNoAuth, store),
                            },
                            {
                                path: "forgot-password",
                                component: function () {
                                    return import("../components/auth/ForgotPassword.vue");
                                },
                                beforeEnter: partial(requireNoAuth, store),
                            },
                            {
                                path: "forgot-password/sent",
                                component: function () {
                                    return import("../components/auth/ForgotPasswordSent.vue");
                                },
                                beforeEnter: partial(requireNoAuth, store),
                            },
                            {
                                path: "reset-password/:userId/:token",
                                component: function () { return import("../components/auth/ResetPassword.vue"); },
                                beforeEnter: partial(requireNoAuth, store),
                            },
                            {
                                path: "forbidden",
                                component: function () { return import("../components/auth/Forbidden.vue"); },
                            },
                            {
                                path: "*",
                                component: function () { return import("../components/ui/PageNotFound.vue"); },
                            },
                        ],
                    },
                ],
            },
        ],
        scrollBehavior: function (to, from, savedPosition) {
            if (nonScrollToTopPrefixes.some(function (prefix) {
                return to.path.startsWith(prefix) && from.path.startsWith(prefix);
            })) {
                return;
            }
            if (savedPosition) {
                return savedPosition;
            }
            return { x: 0, y: 0 };
        },
    });
}
/* eslint-enable @typescript-eslint/explicit-function-return-type */
