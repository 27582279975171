import "whatwg-fetch";
import "classlist-polyfill";
import "regenerator-runtime/runtime";
import { MglMap, MglMarker, MglPopup, MglNavigationControl } from "vue-mapbox";
// Mapbox does something like provide its own promise polyfill. When that happens,
// it doesn't include finally which some other lib needs. Only solution i found was
// to include core-js after it.
// Note that this is only an issue on IE
import "core-js";
import createVueApp from "./app";
import toPairs from "lodash/toPairs";
import Vue from "vue";
import VueAwesomeSwiper from "vue-awesome-swiper";
import Datepicker from "vuejs-datepicker";
import Vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import VueIntercom from "vue-intercom";
import * as Sentry from "@sentry/browser";
import * as Integrations from "@sentry/integrations";
// Polyfill for ie11
if (!SVGElement.prototype.contains) {
    SVGElement.prototype.contains = HTMLDivElement.prototype.contains;
}
if (process.env.SENTRY_DSN) {
    Sentry.init({
        dsn: process.env.SENTRY_DSN,
        integrations: [new Integrations.Vue({ Vue: Vue, attachProps: true })],
    });
}
Vue.use(VueIntercom, { appId: process.env.INTERCOM_APP_ID });
Vue.use(VueAwesomeSwiper);
// These are in entry even though only used in one place so doesn't cause ssr error
Vue.component("MglMap", MglMap);
Vue.component("MglMarker", MglMarker);
Vue.component("MglNavigationControl", MglNavigationControl);
Vue.component("MglPopup", MglPopup);
Vue.component("VDatePicker", Datepicker);
Vue.component("VueDropzone", Vue2Dropzone);
// Vh units fix. See https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
function updateVHUnits() {
    var vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", "".concat(vh, "px"));
}
window.addEventListener("resize", updateVHUnits);
updateVHUnits();
var app = createVueApp({
    debug: process.env.NODE_ENV !== "production",
    apiBaseUrl: window.__API_BASE_URL__,
    initStoreState: window.__INITIAL_STATE__,
});
app.$router.onReady(function () {
    app.$mount(document.body.children[0]);
});
toPairs(window.__APOLLO_STATE__).forEach(function (_a) {
    var clientName = _a[0], state = _a[1];
    if (app.$apolloProvider) {
        app.$apolloProvider.clients[clientName].cache.restore(state);
    }
});
