var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import Vue from "vue";
export default Vue.extend({
    props: {
        placeholder: {
            type: String,
            required: true,
        },
        className: {
            type: String,
            default: undefined,
        },
        options: {
            type: Array,
            required: true,
        },
        disabled: {
            type: Boolean,
            required: true,
        },
        value: {
            type: Array,
            default: function () {
                return [];
            },
        },
        closeOnSelect: {
            type: Boolean,
            default: false,
        },
    },
    data: function () {
        return {
            isOpen: false,
        };
    },
    methods: {
        onToggleMenu: function () {
            this.isOpen = !this.isOpen;
        },
        onHideMenu: function () {
            this.isOpen = false;
        },
        // onClear(): void {
        //   this.$emit("input", []);
        //   if (this.closeOnSelect) {
        //     this.isOpen = false;
        //   }
        // },
        onSetOptionValue: function (optionValue, checked) {
            var _a, _b, _c;
            if (this.closeOnSelect) {
                this.isOpen = false;
            }
            var includes = this.value.includes(optionValue);
            if (includes === checked) {
                return;
            }
            if (checked) {
                this.$emit("input", __spreadArray(__spreadArray([], ((_a = this.value) !== null && _a !== void 0 ? _a : []), true), [optionValue], false));
                return;
            }
            this.$emit("input", (_c = (_b = this.value) === null || _b === void 0 ? void 0 : _b.filter(function (v) { return v !== optionValue; })) !== null && _c !== void 0 ? _c : []);
        },
    },
});
