var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import Vue from "vue";
import gql from "graphql-tag";
import { createMetaDescription } from "../../utils/meta";
export default Vue.extend({
    props: {
        pageKey: {
            type: String,
            required: true,
        },
        variant: {
            type: String,
            default: "h1",
        },
    },
    data: function () {
        return {};
    },
    apollo: {
        contentPage: {
            query: gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n        query ContentPageBannerQuery($pageKey: String!) {\n          contentPage(key: $pageKey) {\n            heading\n            subHeading\n            ctaLabel\n            ctaLink\n            metaTitle\n            metaDescription\n          }\n        }\n      "], ["\n        query ContentPageBannerQuery($pageKey: String!) {\n          contentPage(key: $pageKey) {\n            heading\n            subHeading\n            ctaLabel\n            ctaLink\n            metaTitle\n            metaDescription\n          }\n        }\n      "]))),
            variables: function () {
                return { pageKey: this.pageKey };
            },
        },
    },
    metaInfo: function () {
        if (!this.contentPage) {
            return {};
        }
        return {
            title: this.contentPage.metaTitle,
            meta: [createMetaDescription(this.contentPage.metaDescription)],
        };
    },
});
var templateObject_1;
