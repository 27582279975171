import Vue from "vue";
export default Vue.extend({
    components: {
        MdPortal: function () { return import("./MdPortal"); },
    },
    props: {
        isDark: { type: Boolean, default: false },
        title: { type: String, default: undefined },
        fullScreen: { type: Boolean, default: false },
    },
    computed: {
        isCloseVisible: function () {
            return "cancel" in this.$listeners;
        },
    },
    mounted: function () {
        document.body.classList.add("full-screen-modal-open");
    },
    beforeDestroy: function () {
        document.body.classList.remove("full-screen-modal-open");
    },
});
