import Vue from "vue";
import moment, { isMoment } from "moment";
import mapValues from "lodash/mapValues";
export default Vue.extend({
    props: {
        name: {
            type: String,
            required: true,
        },
        disabledDates: {
            type: Object,
            default: undefined,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            required: true,
        },
        value: {
            type: Object,
            default: undefined,
        },
        status: {
            type: Object,
            default: undefined,
        },
        openMode: {
            type: String,
            default: "today",
        },
        openUpwards: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        datePickerValue: function () {
            if (!this.value) {
                return undefined;
            }
            if (isMoment(this.value)) {
                return this.value.toDate();
            }
            return this.value;
        },
        openDate: function () {
            if (this.openMode === "date-of-birth") {
                return moment().subtract(18, "years").toDate();
            }
            return new Date();
        },
        datePickerDisabledDates: function () {
            if (!this.disabledDates) {
                return undefined;
            }
            return mapValues(this.disabledDates, function (date) {
                return date ? date.toDate() : undefined;
            });
        },
    },
    methods: {
        onInput: function (value) {
            if (!value) {
                this.$emit("input", undefined);
                return;
            }
            this.$emit("input", moment(value));
        },
    },
});
