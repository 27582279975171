import Vue from "vue";
export default Vue.extend({
    props: {
        name: {
            type: String,
            required: true,
        },
        options: {
            type: Array,
            required: true,
        },
        value: {
            type: String,
            default: null,
        },
        status: {
            type: Object,
            required: true,
        },
    },
});
