import Vue from "vue";
import { AsYouType } from "libphonenumber-js";
import phoneCountries from "./phoneCountries";
export default Vue.extend({
    props: {
        name: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
        value: {
            type: String,
            default: undefined,
        },
        status: {
            type: Object,
            required: true,
        },
        placeholder: {
            type: String,
            default: undefined,
        },
    },
    data: function () {
        var preferredCodes = ["AU", "CN"];
        var options = phoneCountries.slice();
        options.sort(function (a, b) {
            var aPos = preferredCodes.includes(a.countryCode)
                ? preferredCodes.indexOf(a.countryCode)
                : Number.MAX_VALUE;
            var bPos = preferredCodes.includes(b.countryCode)
                ? preferredCodes.indexOf(b.countryCode)
                : Number.MAX_VALUE;
            return aPos - bPos;
        });
        return {
            reRenderCount: 0,
            options: options.map(function (_a) {
                var label = _a.label, dialCode = _a.dialCode, countryCode = _a.countryCode;
                return ({
                    label: label,
                    countryCode: countryCode,
                    value: dialCode,
                });
            }),
        };
    },
    computed: {
        forcedReRenderKey: function () {
            return "".concat(this.name, "-").concat(this.reRenderCount);
        },
        inputValue: function () {
            return this.valueComponents[1];
        },
        formattedInputValue: function () {
            return new AsYouType(this.selectedCountryCode).input(this.inputValue);
        },
        valueComponents: function () {
            if (this.value.indexOf("+") !== 0) {
                return [undefined, this.value];
            }
            var allComps = this.value.split(" ");
            return [allComps[0].substring(1), allComps.slice(1).join("")];
        },
        selectValue: function () {
            return this.valueComponents[0];
        },
        selectedCountryCode: function () {
            var _this = this;
            var country = phoneCountries.find(function (_a) {
                var dialCode = _a.dialCode;
                return dialCode === _this.selectValue;
            });
            if (!country) {
                return undefined;
            }
            return country.countryCode;
        },
    },
    watch: {
        // When setting value to null from outside, doesn't reflect in comp. This gets around it
        // Also had some issue with setting value from outside so had to expand the above a bit
        value: function (newValue, oldValue) {
            if (!!newValue !== !!oldValue) {
                this.reRenderCount++;
            }
        },
    },
    methods: {
        onSelectInput: function (value) {
            this.$emit("input", "+".concat(value, " ").concat(this.inputValue));
        },
        onInput: function (event) {
            var target = event.target;
            if (!target) {
                return;
            }
            var newValue = "";
            if (this.selectValue) {
                newValue += "+" + this.selectValue + " ";
            }
            newValue += target.value;
            this.$emit("input", newValue);
        },
    },
});
