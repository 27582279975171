var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import Vue from "vue";
import gql from "graphql-tag";
import striptags from "striptags";
export default Vue.extend({
    props: {
        pageKey: {
            type: String,
            required: true,
        },
    },
    data: function () {
        return {};
    },
    apollo: {
        contentPage: {
            query: gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n        query ContentPageQuery($key: String!) {\n          contentPage(key: $key) {\n            content\n            metaTitle\n            metaDescription\n            heading\n            subHeading\n            bannerImageUrl\n\n            featureTitle1\n            featureText1\n            featureTitle2\n            featureText2\n            featureTitle3\n            featureText3\n            featureTitle4\n            featureText4\n          }\n        }\n      "], ["\n        query ContentPageQuery($key: String!) {\n          contentPage(key: $key) {\n            content\n            metaTitle\n            metaDescription\n            heading\n            subHeading\n            bannerImageUrl\n\n            featureTitle1\n            featureText1\n            featureTitle2\n            featureText2\n            featureTitle3\n            featureText3\n            featureTitle4\n            featureText4\n          }\n        }\n      "]))),
            variables: function () {
                return { key: this.pageKey };
            },
        },
    },
    computed: {
        plainTextHeading: function () {
            if (!this.contentPage) {
                return;
            }
            return striptags(this.contentPage.heading);
        },
    },
});
var templateObject_1;
