import Vue from "vue";
export default Vue.extend({
    props: {
        name: {
            type: String,
            required: true,
        },
        options: {
            type: Array,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
        value: {
            type: String,
            default: null,
        },
        status: {
            type: Object,
            default: undefined,
        },
        searchable: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data: function () {
        return {
            reRenderCount: 0,
        };
    },
    computed: {
        forcedReRenderKey: function () {
            return "".concat(this.name, "-").concat(this.reRenderCount);
        },
    },
    watch: {
        // When setting value to null from outside, doesn't reflect in comp. This gets around it
        // Also had some issue with setting value from outside so had to expand the above a bit
        value: function (newValue, oldValue) {
            if (!!newValue !== !!oldValue) {
                this.reRenderCount++;
            }
        },
    },
});
