import Vue from "vue";
export default Vue.extend({
    props: {
        color: {
            type: String,
            default: undefined,
            validator: function (value) {
                return ["orange", "link", "white"].indexOf(value) >= 0;
            },
        },
        type: {
            type: String,
            default: undefined,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        fullWidth: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        classes: function () {
            var names = ["btn"];
            switch (this.color) {
                case "orange":
                    names.push("btn-orange");
                    break;
                case "link":
                    names.push("btn-link");
                    break;
                case "white":
                    names.push("btn-white");
                    break;
            }
            if (this.fullWidth) {
                names.push("btn-block");
            }
            return names;
        },
    },
});
