import Vue from "vue";
export default Vue.extend({
    props: {
        showContact: {
            type: Boolean,
            default: true,
        },
        showWhatsapp: {
            type: Boolean,
            default: true,
        },
    },
    data: function () {
        return {
            isWeChatOpen: false,
        };
    },
    methods: {
        toggleWeChat: function () {
            this.isWeChatOpen = !this.isWeChatOpen;
        },
    },
});
