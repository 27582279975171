import Vue from "vue";
export default Vue.extend({
    props: {
        status: {
            type: Object,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
        workingLabel: {
            type: String,
            default: undefined,
        },
        fullWidth: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        disabledOnSuccess: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        useDisabled: function () {
            return (this.disabled ||
                this.status.isWorking ||
                (this.disabledOnSuccess && this.status.isSuccess));
        },
        useLabel: function () {
            if (!this.workingLabel) {
                return this.label;
            }
            if (this.status.isWorking) {
                return this.workingLabel;
            }
            if (this.disabledOnSuccess && this.status.isSuccess) {
                return this.workingLabel;
            }
            return this.label;
        },
    },
});
