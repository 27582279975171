import Vue from "vue";
import find from "lodash/find";
export default Vue.extend({
    props: {
        prop: {
            type: String,
            required: true,
        },
    },
    computed: {
        error: function () {
            var _this = this;
            // Pretty sure this is not the best way. Currently errors.first(this.prop)
            // doesn't return anything. probably related to error bag scoping or something
            // I havent encountered yet
            var error = this.$validator.errors &&
                find(this.$validator.errors.items, function (_a) {
                    var field = _a.field;
                    return field === _this.prop;
                });
            if (!error) {
                return undefined;
            }
            return error.msg;
        },
    },
});
