import Vue from "vue";
export default Vue.extend({
    props: {
        name: {
            type: String,
            required: true,
        },
        type: {
            type: String,
            default: "text",
        },
        label: {
            type: String,
            required: true,
        },
        value: {
            type: String,
            required: true,
        },
        status: {
            type: Object,
            required: true,
        },
        autofocus: {
            type: Boolean,
            default: false,
        },
        disablePaste: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String,
            default: undefined,
        },
    },
    methods: {
        onPaste: function (e) {
            if (this.disablePaste) {
                e.preventDefault();
            }
        },
    },
});
