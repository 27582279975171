import Vue from "vue";
import getAbsoluteUrl from "../../router/getAbsoluteUrl";
import qrcode from "qrcode";
export default Vue.extend({
    props: {
        className: {
            type: String,
            default: "flex",
        },
        shareContent: {
            type: String,
            default: undefined,
        },
    },
    data: function () {
        return {
            isWeChatOpen: false,
            weChatImageSource: undefined,
        };
    },
    computed: {
        shareLink: function () {
            return getAbsoluteUrl(this.$store, this.$router, this.$route.fullPath);
        },
        sharePhrase: function () {
            if (!this.shareContent) {
                return this.shareLink;
            }
            return "".concat(this.shareContent, " ").concat(this.shareLink);
        },
        twitterLink: function () {
            return "https://twitter.com/intent/tweet?text=".concat(encodeURIComponent(this.sharePhrase));
        },
        facebookLink: function () {
            return "https://www.facebook.com/sharer/sharer.php?u=".concat(encodeURIComponent(this.shareLink));
        },
        whatsappLink: function () {
            return "https://api.whatsapp.com/send?text=".concat(encodeURIComponent(this.sharePhrase));
        },
        emailLink: function () {
            return "mailto:?subject=".concat(encodeURIComponent("Studentguru"), "&body=").concat(encodeURIComponent(this.sharePhrase));
        },
    },
    methods: {
        toggleWeChat: function () {
            var _this = this;
            this.isWeChatOpen = !this.isWeChatOpen;
            if (this.isWeChatOpen) {
                qrcode
                    .toDataURL(this.shareLink, { width: 200 })
                    .then(function (imageSource) {
                    _this.weChatImageSource = imageSource;
                });
            }
        },
    },
});
