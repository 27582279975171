// From vue-tel-input
var allCountries = [
    ["Afghanistan (‫افغانستان‬‎)", "AF", "93"],
    ["Albania (Shqipëri)", "AL", "355"],
    ["Algeria (‫الجزائر‬‎)", "DZ", "213"],
    ["American Samoa", "AS", "1684"],
    ["Andorra", "AD", "376"],
    ["Angola", "AO", "244"],
    ["Anguilla", "AI", "1264"],
    ["Antigua and Barbuda", "AG", "1268"],
    ["Argentina", "AR", "54"],
    ["Armenia (Հայաստան)", "AM", "374"],
    ["Aruba", "AW", "297"],
    ["Australia", "AU", "61", 0],
    ["Austria (Österreich)", "AT", "43"],
    ["Azerbaijan (Azərbaycan)", "AZ", "994"],
    ["Bahamas", "BS", "1242"],
    ["Bahrain (‫البحرين‬‎)", "BH", "973"],
    ["Bangladesh (বাংলাদেশ)", "BD", "880"],
    ["Barbados", "BB", "1246"],
    ["Belarus (Беларусь)", "BY", "375"],
    ["Belgium (België)", "BE", "32"],
    ["Belize", "BZ", "501"],
    ["Benin (Bénin)", "BJ", "229"],
    ["Bermuda", "BM", "1441"],
    ["Bhutan (འབྲུག)", "BT", "975"],
    ["Bolivia", "BO", "591"],
    ["Bosnia and Herzegovina (Босна и Херцеговина)", "BA", "387"],
    ["Botswana", "BW", "267"],
    ["Brazil (Brasil)", "BR", "55"],
    ["British Indian Ocean Territory", "IO", "246"],
    ["British Virgin Islands", "VG", "1284"],
    ["Brunei", "BN", "673"],
    ["Bulgaria (България)", "BG", "359"],
    ["Burkina Faso", "BF", "226"],
    ["Burundi (Uburundi)", "BI", "257"],
    ["Cambodia (កម្ពុជា)", "KH", "855"],
    ["Cameroon (Cameroun)", "CM", "237"],
    ["Canada", "CA", "1", 1],
    ["Cape Verde (Kabu Verdi)", "CV", "238"],
    ["Caribbean Netherlands", "BQ", "599", 1],
    ["Cayman Islands", "KY", "1345"],
    ["Central African Republic (République centrafricaine)", "CF", "236"],
    ["Chad (Tchad)", "TD", "235"],
    ["Chile", "CL", "56"],
    ["China (中国)", "CN", "86"],
    ["Christmas Island", "CX", "61", 2],
    ["Cocos (Keeling) Islands", "CC", "61", 1],
    ["Colombia", "CO", "57"],
    ["Comoros (‫جزر القمر‬‎)", "KM", "269"],
    ["Congo (DRC) (Jamhuri ya Kidemokrasia ya Kongo)", "CD", "243"],
    ["Congo (Republic) (Congo-Brazzaville)", "CG", "242"],
    ["Cook Islands", "CK", "682"],
    ["Costa Rica", "CR", "506"],
    ["Côte d’Ivoire", "CI", "225"],
    ["Croatia (Hrvatska)", "HR", "385"],
    ["Cuba", "CU", "53"],
    ["Curaçao", "CW", "599", 0],
    ["Cyprus (Κύπρος)", "CY", "357"],
    ["Czech Republic (Česká republika)", "CZ", "420"],
    ["Denmark (Danmark)", "DK", "45"],
    ["Djibouti", "DJ", "253"],
    ["Dominica", "DM", "1767"],
    ["Dominican Republic (República Dominicana)", "DO", "1", 2],
    ["Ecuador", "EC", "593"],
    ["Egypt (‫مصر‬‎)", "EG", "20"],
    ["El Salvador", "SV", "503"],
    ["Equatorial Guinea (Guinea Ecuatorial)", "GQ", "240"],
    ["Eritrea", "ER", "291"],
    ["Estonia (Eesti)", "EE", "372"],
    ["Ethiopia", "ET", "251"],
    ["Falkland Islands (Islas Malvinas)", "FK", "500"],
    ["Faroe Islands (Føroyar)", "FO", "298"],
    ["Fiji", "FJ", "679"],
    ["Finland (Suomi)", "FI", "358", 0],
    ["France", "FR", "33"],
    ["French Guiana (Guyane française)", "GF", "594"],
    ["French Polynesia (Polynésie française)", "PF", "689"],
    ["Gabon", "GA", "241"],
    ["Gambia", "GM", "220"],
    ["Georgia (საქართველო)", "GE", "995"],
    ["Germany (Deutschland)", "DE", "49"],
    ["Ghana (Gaana)", "GH", "233"],
    ["Gibraltar", "GI", "350"],
    ["Greece (Ελλάδα)", "GR", "30"],
    ["Greenland (Kalaallit Nunaat)", "GL", "299"],
    ["Grenada", "GD", "1473"],
    ["Guadeloupe", "GP", "590", 0],
    ["Guam", "GU", "1671"],
    ["Guatemala", "GT", "502"],
    ["Guernsey", "GG", "44", 1],
    ["Guinea (Guinée)", "GN", "224"],
    ["Guinea-Bissau (Guiné Bissau)", "GW", "245"],
    ["Guyana", "GY", "592"],
    ["Haiti", "HT", "509"],
    ["Honduras", "HN", "504"],
    ["Hong Kong (香港)", "HK", "852"],
    ["Hungary (Magyarország)", "HU", "36"],
    ["Iceland (Ísland)", "IS", "354"],
    ["India (भारत)", "IN", "91"],
    ["Indonesia", "ID", "62"],
    ["Iran (‫ایران‬‎)", "IR", "98"],
    ["Iraq (‫العراق‬‎)", "IQ", "964"],
    ["Ireland", "IE", "353"],
    ["Isle of Man", "IM", "44", 2],
    ["Israel (‫ישראל‬‎)", "IL", "972"],
    ["Italy (Italia)", "IT", "39", 0],
    ["Jamaica", "JM", "1876"],
    ["Japan (日本)", "JP", "81"],
    ["Jersey", "JE", "44", 3],
    ["Jordan (‫الأردن‬‎)", "JO", "962"],
    ["Kazakhstan (Казахстан)", "KZ", "7", 1],
    ["Kenya", "KE", "254"],
    ["Kiribati", "KI", "686"],
    ["Kosovo", "XK", "383"],
    ["Kuwait (‫الكويت‬‎)", "KW", "965"],
    ["Kyrgyzstan (Кыргызстан)", "KG", "996"],
    ["Laos (ລາວ)", "LA", "856"],
    ["Latvia (Latvija)", "LV", "371"],
    ["Lebanon (‫لبنان‬‎)", "LB", "961"],
    ["Lesotho", "LS", "266"],
    ["Liberia", "LR", "231"],
    ["Libya (‫ليبيا‬‎)", "LY", "218"],
    ["Liechtenstein", "LI", "423"],
    ["Lithuania (Lietuva)", "LT", "370"],
    ["Luxembourg", "LU", "352"],
    ["Macau (澳門)", "MO", "853"],
    ["Macedonia (FYROM) (Македонија)", "MK", "389"],
    ["Madagascar (Madagasikara)", "MG", "261"],
    ["Malawi", "MW", "265"],
    ["Malaysia", "MY", "60"],
    ["Maldives", "MV", "960"],
    ["Mali", "ML", "223"],
    ["Malta", "MT", "356"],
    ["Marshall Islands", "MH", "692"],
    ["Martinique", "MQ", "596"],
    ["Mauritania (‫موريتانيا‬‎)", "MR", "222"],
    ["Mauritius (Moris)", "MU", "230"],
    ["Mayotte", "YT", "262", 1],
    ["Mexico (México)", "MX", "52"],
    ["Micronesia", "FM", "691"],
    ["Moldova (Republica Moldova)", "MD", "373"],
    ["Monaco", "MC", "377"],
    ["Mongolia (Монгол)", "MN", "976"],
    ["Montenegro (Crna Gora)", "ME", "382"],
    ["Montserrat", "MS", "1664"],
    ["Morocco (‫المغرب‬‎)", "MA", "212", 0],
    ["Mozambique (Moçambique)", "MZ", "258"],
    ["Myanmar (Burma) (မြန်မာ)", "MM", "95"],
    ["Namibia (Namibië)", "NA", "264"],
    ["Nauru", "NR", "674"],
    ["Nepal (नेपाल)", "NP", "977"],
    ["Netherlands (Nederland)", "NL", "31"],
    ["New Caledonia (Nouvelle-Calédonie)", "NC", "687"],
    ["New Zealand", "NZ", "64"],
    ["Nicaragua", "NI", "505"],
    ["Niger (Nijar)", "NE", "227"],
    ["Nigeria", "NG", "234"],
    ["Niue", "NU", "683"],
    ["Norfolk Island", "NF", "672"],
    ["North Korea (조선 민주주의 인민 공화국)", "KP", "850"],
    ["Northern Mariana Islands", "MP", "1670"],
    ["Norway (Norge)", "NO", "47", 0],
    ["Oman (‫عُمان‬‎)", "OM", "968"],
    ["Pakistan (‫پاکستان‬‎)", "PK", "92"],
    ["Palau", "PW", "680"],
    ["Palestine (‫فلسطين‬‎)", "PS", "970"],
    ["Panama (Panamá)", "PA", "507"],
    ["Papua New Guinea", "PG", "675"],
    ["Paraguay", "PY", "595"],
    ["Peru (Perú)", "PE", "51"],
    ["Philippines", "PH", "63"],
    ["Poland (Polska)", "PL", "48"],
    ["Portugal", "PT", "351"],
    ["Puerto Rico", "PR", "1", 3],
    ["Qatar (‫قطر‬‎)", "QA", "974"],
    ["Réunion (La Réunion)", "RE", "262", 0],
    ["Romania (România)", "RO", "40"],
    ["Russia (Россия)", "RU", "7", 0],
    ["Rwanda", "RW", "250"],
    ["Saint Barthélemy", "BL", "590", 1],
    ["Saint Helena", "SH", "290"],
    ["Saint Kitts and Nevis", "KN", "1869"],
    ["Saint Lucia", "LC", "1758"],
    ["Saint Martin (Saint-Martin (partie française))", "MF", "590", 2],
    ["Saint Pierre and Miquelon (Saint-Pierre-et-Miquelon)", "PM", "508"],
    ["Saint Vincent and the Grenadines", "VC", "1784"],
    ["Samoa", "WS", "685"],
    ["San Marino", "SM", "378"],
    ["São Tomé and Príncipe (São Tomé e Príncipe)", "ST", "239"],
    ["Saudi Arabia (‫المملكة العربية السعودية‬‎)", "SA", "966"],
    ["Senegal (Sénégal)", "SN", "221"],
    ["Serbia (Србија)", "RS", "381"],
    ["Seychelles", "SC", "248"],
    ["Sierra Leone", "SL", "232"],
    ["Singapore", "SG", "65"],
    ["Sint Maarten", "SX", "1721"],
    ["Slovakia (Slovensko)", "SK", "421"],
    ["Slovenia (Slovenija)", "SI", "386"],
    ["Solomon Islands", "SB", "677"],
    ["Somalia (Soomaaliya)", "SO", "252"],
    ["South Africa", "ZA", "27"],
    ["South Korea (대한민국)", "KR", "82"],
    ["South Sudan (‫جنوب السودان‬‎)", "SS", "211"],
    ["Spain (España)", "ES", "34"],
    ["Sri Lanka (ශ්‍රී ලංකාව)", "LK", "94"],
    ["Sudan (‫السودان‬‎)", "SD", "249"],
    ["Suriname", "SR", "597"],
    ["Svalbard and Jan Mayen", "SJ", "47", 1],
    ["Swaziland", "SZ", "268"],
    ["Sweden (Sverige)", "SE", "46"],
    ["Switzerland (Schweiz)", "CH", "41"],
    ["Syria (‫سوريا‬‎)", "SY", "963"],
    ["Taiwan (台灣)", "TW", "886"],
    ["Tajikistan", "TJ", "992"],
    ["Tanzania", "TZ", "255"],
    ["Thailand (ไทย)", "TH", "66"],
    ["Timor-Leste", "TL", "670"],
    ["Togo", "TG", "228"],
    ["Tokelau", "TK", "690"],
    ["Tonga", "TO", "676"],
    ["Trinidad and Tobago", "TT", "1868"],
    ["Tunisia (‫تونس‬‎)", "TN", "216"],
    ["Turkey (Türkiye)", "TR", "90"],
    ["Turkmenistan", "TM", "993"],
    ["Turks and Caicos Islands", "TC", "1649"],
    ["Tuvalu", "TV", "688"],
    ["U.S. Virgin Islands", "VI", "1340"],
    ["Uganda", "UG", "256"],
    ["Ukraine (Україна)", "UA", "380"],
    ["United Arab Emirates (‫الإمارات العربية المتحدة‬‎)", "AE", "971"],
    ["United Kingdom", "GB", "44", 0],
    ["United States", "US", "1", 0],
    ["Uruguay", "UY", "598"],
    ["Uzbekistan (Oʻzbekiston)", "UZ", "998"],
    ["Vanuatu", "VU", "678"],
    ["Vatican City (Città del Vaticano)", "VA", "39", 1],
    ["Venezuela", "VE", "58"],
    ["Vietnam (Việt Nam)", "VN", "84"],
    ["Wallis and Futuna (Wallis-et-Futuna)", "WF", "681"],
    ["Western Sahara (‫الصحراء الغربية‬‎)", "EH", "212", 1],
    ["Yemen (‫اليمن‬‎)", "YE", "967"],
    ["Zambia", "ZM", "260"],
    ["Zimbabwe", "ZW", "263"],
    ["Åland Islands", "AX", "358", 1],
];
export default allCountries.map(function (country) { return ({
    label: country[0],
    countryCode: country[1],
    dialCode: country[2],
    priority: country[3] || 0,
}); });
