import Vue from "vue";
import App from "./components/App.vue";
import VueRouter from "vue-router";
import VeeValidate, { Validator } from "vee-validate";
import VueApollo from "vue-apollo";
import createApolloClient from "./api/createApolloClient";
import VueI18n from "vue-i18n";
import VueMeta from "vue-meta";
import { ContentLoader } from "vue-content-loader";
import VueGtm from "vue-gtm";
import vClickOutside from "v-click-outside";
import VTooltip from "v-tooltip";
import VueSelect from "vue-select";
import VueMoment from "vue-moment";
import "./styles/main.scss";
import localeMessages from "./locale/messages.json";
import Breadcrumbs from "./components/ui/Breadcrumbs.vue";
import PageNotFound from "./components/ui/PageNotFound.vue";
import PageLoadingDeprecated from "./components/ui/PageLoadingDeprecated.vue";
import numeral from "numeral";
import numFormat from "vue-filter-number-format";
import NoSsr from "vue-no-ssr";
import Vuex from "vuex";
import { MediaQueryProvider, MatchMedia } from "vue-component-media-queries";
import truncate from "vue-truncate-collapsed";
import { moneyFormat, moneyFormatAud, nl2br, obscuredCard, } from "./utils/filters";
import { createStore } from "./store";
import { createRouter } from "./router";
import Dialog from "./components/ui/dialog/Dialog.vue";
import FormSubmitButton from "./components/ui/form/FormSubmitButton.vue";
import FieldError from "./components/ui/form/FieldError.vue";
import honeyPot from "./validation/honeyPot";
import notDisabledDates, { dateOfBirth, futureDate, } from "./validation/notDisabledDates";
import createEnglishOnly from "./validation/englishOnly";
import createUserPassword from "./validation/userPassword";
import Page from "./components/ui/Page.vue";
import "vue-progress-path/dist/vue-progress-path.css";
import VueProgress from "vue-progress-path";
import ContentPage from "./components/public/general/ContentPage.vue";
import FormErrorMessage from "./components/ui/form/FormErrorMessage.vue";
import ConfirmDialog from "./components/ui/dialog/ConfirmDialog.vue";
import Button from "./components/ui/Button.vue";
import FormItem from "./components/ui/form/FormItem.vue";
import FormItemInput from "./components/ui/form/FormItemInput.vue";
import FormItemSelect from "./components/ui/form/FormItemSelect.vue";
import FormItemDate from "./components/ui/form/FormItemDate.vue";
import AlertDialog from "./components/ui/dialog/AlertDialog.vue";
import DashCard from "./components/ui/DashCard.vue";
import FormItemPhoneNumber from "./components/ui/form/FormItemPhoneNumber.vue";
import FormItemTextarea from "./components/ui/form/FormItemTextarea.vue";
import FormItemCheckbox from "./components/ui/form/FormItemCheckbox.vue";
import VueGoogleCharts from "vue-google-charts";
import FormItemRadioGroup from "./components/ui/form/FormItemRadioGroup.vue";
import PillsSelect from "./components/ui/form/PillsSelect.vue";
import PillsSelectSingle from "./components/ui/form/PillsSelectSingle.vue";
import OpenCloseIcon from "./components/ui/OpenCloseIcon.vue";
import SocialShareLinks from "./components/ui/SocialShareLinks.vue";
import SocialSgLinks from "./components/ui/SocialSgLinks.vue";
import HoneyPotField from "./components/ui/form/HoneyPotField.vue";
import ContentPageBanner from "./components/ui/ContentPageBanner.vue";
import { displayName } from "./utils/institution";
Vue.use(vClickOutside);
Vue.use(VueGoogleCharts);
// TODO :Remove once all generic deprecated loaders are gone
Vue.use(VueProgress);
Vue.use(Vuex);
Vue.use(VueRouter);
Vue.use(VeeValidate, {
    classes: true,
    classNames: {
        invalid: "invalid-input",
    },
});
Vue.use(VueApollo);
Vue.use(VueMeta);
Vue.use(VueI18n);
Vue.use(VueMoment);
Vue.use(VTooltip);
Vue.filter("numFormat", numFormat(numeral));
Vue.filter("money", moneyFormat);
Vue.filter("moneyAud", moneyFormatAud);
Vue.filter("nl2br", nl2br);
Vue.filter("obscuredCard", obscuredCard);
Vue.filter("institutionDisplayName", displayName);
Vue.component("ContentPageBanner", ContentPageBanner);
Vue.component("VSelect", VueSelect);
Vue.component("NoSsr", NoSsr);
Vue.component("Truncate", truncate);
Vue.component("Breadcrumbs", Breadcrumbs);
Vue.component("PageNotFound", PageNotFound);
Vue.component("PageLoadingDeprecated", PageLoadingDeprecated);
Vue.component("ContentLoader", ContentLoader);
Vue.component("SgDialog", Dialog);
Vue.component("SgAlert", AlertDialog);
Vue.component("ConfirmDialog", ConfirmDialog);
Vue.component("FormSubmitButton", FormSubmitButton);
Vue.component("FieldError", FieldError);
Vue.component("FormErrorMessage", FormErrorMessage);
Vue.component("Page", Page);
Vue.component("ContentPage", ContentPage);
Vue.component("SgButton", Button);
Vue.component("DashCard", DashCard);
Vue.component("FormItem", FormItem);
Vue.component("FormItemInput", FormItemInput);
Vue.component("FormItemSelect", FormItemSelect);
Vue.component("FormItemDate", FormItemDate);
Vue.component("FormItemPhoneNumber", FormItemPhoneNumber);
Vue.component("FormItemTextarea", FormItemTextarea);
Vue.component("FormItemCheckbox", FormItemCheckbox);
Vue.component("FormItemRadioGroup", FormItemRadioGroup);
Vue.component("PillsSelect", PillsSelect);
Vue.component("PillsSelectSingle", PillsSelectSingle);
Vue.component("HoneyPotField", HoneyPotField);
Vue.component("OpenCloseIcon", OpenCloseIcon);
Vue.component("SocialShareLinks", SocialShareLinks);
Vue.component("SocialSgLinks", SocialSgLinks);
Vue.component("MediaQueryProvider", MediaQueryProvider);
Vue.component("MatchMedia", MatchMedia);
// TODO: Find a way to just translate field names in to startCase
Validator.localize({
    en: {
        messages: {
            required: "Required",
            email: "Please enter a valid email address",
            confirmed: "Please make sure the fields match",
        },
    },
});
Validator.extend("honeyPot", honeyPot);
export default (function (_a) {
    var apiBaseUrl = _a.apiBaseUrl, initStoreState = _a.initStoreState, debug = _a.debug, ssr = _a.ssr;
    var i18n = new VueI18n({
        locale: initStoreState.locale,
        messages: localeMessages,
    });
    var $t = i18n.t.bind(i18n);
    Validator.extend("englishOnly", createEnglishOnly($t));
    Validator.extend("notDisabledDates", notDisabledDates($t));
    Validator.extend("dateOfBirth", dateOfBirth($t));
    Validator.extend("futureDate", futureDate($t));
    Validator.extend("userPassword", createUserPassword($t));
    var store = createStore({
        strict: debug,
        persist: !ssr,
        initState: initStoreState,
    });
    var apolloProvider = new VueApollo({
        defaultClient: createApolloClient({
            store: store,
            uri: "".concat(apiBaseUrl, "/").concat(initStoreState.locale, "/").concat(initStoreState.currency.toLowerCase(), "/graphql/"),
            ssrMode: ssr,
        }),
    });
    var router = createRouter({
        store: store,
        locale: initStoreState.locale,
        currency: initStoreState.currency,
    });
    if (!ssr) {
        Vue.use(VueGtm, {
            id: initStoreState.googleTagManagerId,
            enabled: !!initStoreState.googleTagManagerId,
            debug: true,
            loadScript: true,
            vueRouter: router,
        });
    }
    return new Vue({
        router: router,
        apolloProvider: apolloProvider,
        i18n: i18n,
        store: store,
        components: { App: App },
        template: "<app />",
    });
});
