import moment from "moment";
function getDisabledRange(args) {
    if (args.length !== 2) {
        throw new Error("Invalid disabled range");
    }
    if (args[0] && !args[1]) {
        return { from: moment(args[0]) };
    }
    if (!args[0] && args[1]) {
        return { to: moment(args[1]) };
    }
    throw new Error("Invalid disabled range");
}
function getDisabledDatesMessage($t, disabledDates) {
    if ("from" in disabledDates) {
        return $t("Date must be before {from} and entered in DD/MM/YYYY format", {
            from: disabledDates.from.format("DD/MM/YYYY"),
        }).toString();
    }
    return $t("Date must be after {to} and entered in DD/MM/YYYY format", {
        to: disabledDates.to.format("DD/MM/YYYY"),
    }).toString();
}
function validateDisabledDates(value, disabledDates) {
    if ("from" in disabledDates) {
        return value.isBefore(disabledDates.from);
    }
    return value.isAfter(disabledDates.to);
}
export default function ($t) {
    return {
        getMessage: function (field, args) {
            return getDisabledDatesMessage($t, getDisabledRange(args));
        },
        validate: function (value, args) {
            return validateDisabledDates(value, getDisabledRange(args));
        },
    };
}
export function dateOfBirth($t, today) {
    var useToday = today || moment();
    return {
        getMessage: function () {
            return getDisabledDatesMessage($t, { from: useToday });
        },
        validate: function (value) {
            return validateDisabledDates(value, { from: useToday });
        },
    };
}
export function futureDate($t, today) {
    var useToday = (today || moment()).subtract(1, "day");
    return {
        getMessage: function () {
            return getDisabledDatesMessage($t, { to: useToday });
        },
        validate: function (value) {
            return validateDisabledDates(value, { to: useToday });
        },
    };
}
