export default (function (type) {
    switch (type) {
        case "student":
            return "/dashboard";
        case "agent":
            return "/agent-dashboard";
        default:
            throw new Error("Unknown user type ".concat(type));
    }
});
