var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import Vue from "vue";
import gql from "graphql-tag";
import { mapMutations, mapState } from "vuex";
export default Vue.extend({
    apollo: {
        cart: {
            query: gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n        query AppQuery($id: ID!) {\n          cart(id: $id) {\n            id\n          }\n        }\n      "], ["\n        query AppQuery($id: ID!) {\n          cart(id: $id) {\n            id\n          }\n        }\n      "]))),
            variables: function () {
                return {
                    id: this.cartId,
                };
            },
            skip: function () {
                return !this.cartId;
            },
            result: function (_a) {
                var data = _a.data;
                if (!data || !data.cart) {
                    this.clearCart();
                }
            },
        },
    },
    computed: mapState(["cartId", "googleTagManagerId"]),
    methods: mapMutations(["clearCart"]),
    metaInfo: function () {
        var result = {
            titleTemplate: function (chunk) {
                if (chunk) {
                    return "".concat(chunk, " | Studentguru");
                }
                return "StudentGuru";
            },
        };
        // Handled by vue-gtm now. Might be possible to do this though, see how it
        // goes after some google side tweaking
        /*if (this.googleTagManagerId) {
          result.script = [
            {
              vmid: 'gtm-script',
              once: true,
              innerHTML: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                  })(window,document,'script','dataLayer','${this.googleTagManagerId}');`
            }
          ];
          result.noscript = [
            {
              vmid: 'gtm-noscript',
              once: true,
              innerHTML: `
                <iframe
                  src="//www.googletagmanager.com/ns.html?id=${this.googleTagManagerId}"
                  height="0"
                  width="0"
                  style="display:none;visibility:hidden"
                ></iframe>
              `
            }
          ];
          result.__dangerouslyDisableSanitizersByTagID = {
            "gtm-script": ["innerHTML"],
            "gtm-noscript": ["innerHTML"]
          };
        }*/
        return result;
    },
});
var templateObject_1;
