var RE = /^[a-zA-ZÀ-ž\d$@$!%*?&#^-_. +~`()-_+=[\]{}|"'\s\w]+$/;
export default function ($t) {
    return {
        getMessage: function () {
            return $t("This field must be in English only").toString();
        },
        validate: function (value) {
            return !!value.match(RE);
        },
    };
}
