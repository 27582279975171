var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as queryString from "querystring";
import getUserHomepage from "./getUserHomepage";
var TemporaryRedirectError = /** @class */ (function (_super) {
    __extends(TemporaryRedirectError, _super);
    function TemporaryRedirectError(path) {
        var _this = _super.call(this, "Redirect to ".concat(path)) || this;
        _this.code = 302;
        _this.path = path;
        return _this;
    }
    return TemporaryRedirectError;
}(Error));
export function requireAuth(requiredType, store, to, from, next) {
    var authUser = store.state.authUser;
    if (!authUser) {
        next(new TemporaryRedirectError("/sign-in?" + queryString.stringify({ nextPath: to.fullPath })));
        return;
    }
    if (authUser.type !== requiredType) {
        next(new TemporaryRedirectError("/forbidden"));
        return;
    }
    next();
}
export function requireNoAuth(store, to, from, next) {
    var user = store.state.authUser;
    if (!user) {
        next();
        return;
    }
    next(new TemporaryRedirectError(getUserHomepage(user.type)));
}
