export default function ($t) {
    return {
        getMessage: function () {
            return $t("Passwords must be at least 8 characters").toString();
        },
        validate: function (value) {
            return value.length >= 8;
        },
    };
}
