import Vue from "vue";
export default Vue.extend({
    props: {
        placeholder: {
            type: String,
            required: true,
        },
        options: {
            type: Array,
            required: true,
        },
        disabled: {
            type: Boolean,
            required: true,
        },
        value: {
            type: String,
            default: undefined,
        },
        valueAsPlaceholder: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        valueAsArray: function () {
            if (this.value === undefined ||
                this.value === null ||
                this.value === "") {
                return [];
            }
            return [this.value];
        },
        placeholderToUse: function () {
            var _this = this;
            var _a, _b;
            if (!this.valueAsPlaceholder) {
                return this.placeholder;
            }
            if (this.value === undefined) {
                return this.placeholder;
            }
            return ((_b = (_a = this.options.find(function (o) { return o.value === _this.value; })) === null || _a === void 0 ? void 0 : _a.label) !== null && _b !== void 0 ? _b : this.placeholder);
        },
    },
    methods: {
        onInput: function (newValue) {
            var _this = this;
            if (newValue.length === 0) {
                this.$emit("input", undefined);
                return;
            }
            var diffValue = newValue.filter(function (v) { return v !== _this.value; });
            this.$emit("input", diffValue[0]);
        },
    },
});
