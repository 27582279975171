import Vue from "vue";
export default Vue.extend({
    props: {
        name: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
        value: {
            type: Boolean,
            required: true,
        },
        status: {
            type: Object,
            required: true,
        },
        autofocus: {
            type: Boolean,
            default: false,
        },
    },
});
