import Vue from "vue";
export default Vue.extend({
    props: {
        name: {
            type: String,
            required: true,
        },
        value: {
            type: String,
            required: true,
        },
    },
    methods: {
        onPaste: function (e) {
            e.preventDefault();
        },
    },
});
